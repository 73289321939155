export const MGMT_ROUTES = {
  ASSETS: "/mgmt/assets",
  ASSETS_NEW: "/mgmt/assets/new",
  ASSETS_EDIT: "/mgmt/assets/edit/:assetId",
  ASSETS_VIEW: "/mgmt/assets/:assetId",
  COLLECTIONS: "/mgmt/collections",
  COLLECTIONS_NEW: "/mgmt/collections/new",
  COLLECTIONS_EDIT: "/mgmt/collections/edit/:collectionId",
  COLLECTIONS_VIEW: "/mgmt/collections/:collectionId",
  COLLECTIONS_GALLERY: "/mgmt/collections/:collectionId/gallery",
  COLLECTIONS_MAIN_IMAGES_GALLERY: "/mgmt/collections/:collectionId/main-image-gallery",
  EXHIBITIONS: "/mgmt/exhibitions",
  EXHIBITIONS_NEW: "/mgmt/exhibitions/new",
  EXHIBITIONS_EDIT: "/mgmt/exhibitions/edit/:exhibitionId",
  EXHIBITIONS_VIEW: "/mgmt/exhibitions/:exhibitionId",
  LOGISTIC: "/mgmt/logistic",
  INSURANCE: "/mgmt/insurance",
  APPRAISAL: "/mgmt/appraisal",
  APPRAISAL_NEW: "/mgmt/appraisal/new",
  APPRAISAL_EDIT: "/mgmt/appraisal/edit/:appraisalId",
  APPRAISAL_VIEW: "/mgmt/appraisal/:appraisalId",
  RESTORATION: "/mgmt/restoration",
  RESTORATION_NEW: "/mgmt/restoration/new",
  RESTORATION_EDIT: "/mgmt/restoration/edit/:restorationId",
  RESTORATION_VIEW: "/mgmt/restoration/:restorationId",
  CRSR: "/mgmt/cr-sr",
  CRSR_NEW: "/mgmt/cr-sr/new",
  CRSR_EDIT: "/mgmt/cr-sr/edit/:reportId",
  CRSR_VIEW: "/mgmt/cr-sr/:reportId",
  QUOTATION: "/mgmt/quotation",
  DOCS: "/mgmt/docs",
  ASSETS_LOCATIONS: "/mgmt/assetslocations",
  ASSETS_LOCATION_VIEW: "/mgmt/assetslocations/:addressId",
  COMPARE_ARTWORKS: "/mgmt/compare-artworks",
};

export const SPIN_ROUTES = {
  SPIN: "/spin",
  POLICY_REDIRECT: "/spin/policy-redirect/",
  POLICY_REDIRECT_VIEW: "/spin/policy-redirect/:policyId",
  POLICY_REDIRECT_EDIT: "/spin/policy-redirect/edit/:policyId",
  POLICY_TE: "/spin/te-policies",
  POLICY_TE_EDIT: "/spin/te-policies/edit/:policyId",
  POLICY_TE_NEW: "/spin/te-policies/new",
  POLICY_TE_VIEW: "/spin/te-policies/:policyId",
  CERTIFICATES: "/spin/certificates",
  TEMPLATES: "/spin/certificates/templates",
  POLICY_PC: "/spin/pc-policies",
  POLICY_PC_EDIT: "/spin/pc-policies/edit/:policyId",
  POLICY_PC_NEW: "/spin/pc-policies/new",
  POLICY_PC_VIEW: "/spin/pc-policies/:policyId",
  INVENTORIES: "/spin/inventories",
  POLICY_TE_OBJECTS: "/spin/te-policies-objects",
  POLICY_PC_OBJECTS: "/spin/pc-policies-objects",
};

export const REGISTRY_ROUTES = {
  REGISTRY: "/r/registry",
  PEOPLE: "/r/registry/people",
  COMPANIES: "/r/registry/companies",
  AUTHORS: "/r/registry/authors",
  VIEW: "/r/registry/:registryId",
  NEW: "/r/registry/new",
  EDIT: "/r/registry/edit/:registryId",
};

export const USER_ROUTES = {
  USER: "/u/users",
  VIEW: "/u/users/:userId",
  EDIT: "/u/users/edit/:userId",
  INVITE: "/u/users/invite",
  NEW: "/u/users/new",
};

export const SETTINGS_ROUTES = {
  SETTINGS: "/settings",
  ORGANIZATION: "/settings/organization",
  USAGE: "/settings/usage",
  SLOG: "/settings/slog",
};

export const OTHER_ROUTES = {
  ANALYSIS: "/o/analysis",
  NOT_FOUND: "*",
  SHARED_COLLECTION_GALLERY: "/shared/collections/:collectionId/gallery",
  SHARED_COLLECTION_MAIN_IMAGES_GALLERY: "/shared/collections/:collectionId/main-image-gallery",
  SHARED_TRANSPORTATION_VIEW: "/shared/transportations/:transportationId",
};

export const SLOG_ROUTES = {
  SLOG: "/slog",
  TRANSPORTATIONS: "/slog/transportations",
  TRANSPORTATIONS_VIEW: "/slog/transportations/:transportationId",
  TRANSPORTATIONS_NEW: "/slog/transportations/new",
  TRANSPORTATIONS_EDIT: "/slog/transportations/edit/:transportationId",
  TRANSPORTATIONS_ASSETS: "/slog/transportations-assets",
  DEPOSITATIONS: "/slog/depositations",
  DEPOSITATIONS_VIEW: "/slog/depositations/:depositationId",
  DEPOSITATIONS_NEW: "/slog/depositations/new",
  DEPOSITATIONS_EDIT: "/slog/depositations/edit/:depositationId",
  DEPOSITATIONS_ASSETS: "/slog/depositations-assets",
  TRANSPORTATION_BILLS: "/slog/transportation-bills",
  DEPOSITATION_BILLS: "/slog/depositation-bills",
};
